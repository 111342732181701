import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Link from '../utils/link'
import Seo from '../components/seo'
import ProductCard from '../components/cards/product'
import moment from 'moment'
import ReadyToGetStarted from '../components/ready-to-get-started'

class CategoryTemplate extends Component {

  state = {
    count: 0,
    results: this.props.data.allWpProduct.nodes,
    filters: [],
    colours: [],
    materials: [],
    sort: null,
    colour: null,
    material: null,
    pathname: null
  }

  componentDidMount() {
    this.updateList()
    this.buildFilters()
    this.setState({pathname: window && window.location.pathname})
  }

  toggleFilters = (item) => {
    let { filters } = this.state
    filters[item] = !filters[item]
    this.setState({ filters })
  }

  buildFilters() {
    let products = this.props.data.allWpProduct.nodes
    let colours = [],
        materials = []

    products.length > 0 && products.map((el, i) => {
      el.shopifyProduct.variants.forEach((variant, v) => {
        if (variant.title !== 'Default Title') colours.push(variant.title)
      })
      el.materials?.nodes.length && el.materials.nodes.forEach((material, m) => {
        materials.push(material.name)
      })
    })

    colours = [...new Set(colours)]
    materials = [...new Set(materials)]

    this.setState({
      colours,
      materials,
    })
  }

  handleSortChange = (option) => {
    let { name, value } = option.currentTarget
    let state = {}
    state[name] = value
    this.setState(state, this.sortList(value))
  }

  handleChange = (option) => {
    let { name, value } = option.target
    let state = {}
    state[name] = value
    this.setState(state, this.updateList)
  }

  clearColour = () => {
    let state = {
      colour: null,
    }
    this.setState(state, this.updateList)
    if ( document ) {
      let radio = document.querySelector('input[name="colour"]:checked')
      radio.checked = false
    }
  }

  clearMaterial = () => {
    let state = {
      material: null,
    }
    this.setState(state, this.updateList)
    if ( document ) {
      let radio = document.querySelector('input[name="material"]:checked')
      radio.checked = false
    }
  }

  sortList = (sort) => {
    let { results } = this.state
    if (results.length === 0) return false
    switch (sort) {
      case 'highest-price':
        results.sort((a, b) => parseFloat(a.shopifyProduct.variants[0].price) < parseFloat(b.shopifyProduct.variants[0].price) ? 1 : -1)
        break
      case 'lowest-price':
        results.sort((a, b) => parseFloat(a.shopifyProduct.variants[0].price) < parseFloat(b.shopifyProduct.variants[0].price) ? -1 : 1)
        break
      case 'oldest':
        results.sort((a, b) => moment(a.date).isBefore(b.date) ? -1 : 1)
        break
      default:
        // Sort by date
        results.sort((a, b) => moment(a.date).isBefore(b.date) ? 1 : -1)
        break
    }
    this.setState({results})
  }

  updateList = () => {
    let { colour, material, results } = this.state
    const products = this.props.data.allWpProduct.nodes
    if (products.length > 0) {
      results = products.filter((el, i) => {
        if ( material ) {
          let materials = el.materials.nodes
          if ( materials.length > 0 ) {
            for(let x = 0; x < materials.length; x++) {
              if (materials[x].name === material) return true
            }
          }
          return false
        }
        if ( colour ) {
          let colours = el.shopifyProduct.variants
          if ( colours.length > 0 ) {
            for(let x = 0; x < colours.length; x++) {
              if (colours[x].title === colour) return true
            }
          }
          return false
        }
        return true
      })
    }
    let count = results.length
    this.setState({
      results,
      count
    })
  }

  render() {
    let page = this.props.data.wpProductCategory
    let { breadcrumbs } = this.props.pageContext
    let { results, count, filters, colours, materials, colour, material, pathname } = this.state

    return (
      <>
        <Seo
          bodyClass='shop-archive'
          {...page.seo}
        />
        <section className='breadcrumbs'>
          <div className='breadcrumbs__inner'>
            <ul>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/shop/'>Shop Now</Link></li>
              { breadcrumbs?.map((el, i) => (
                <li key={i}><Link to={breadcrumbs.slice(0, i + 1).reduce((a, b) => `${a}${b.slug}/`, '/shop/')}>{ el.name }</Link></li>
              ))}
            </ul>
          </div>
        </section>
        <section className='range-header'>
          <div className='range-header__inner'>
            <div className='range-header__item'>
              <div className='range-header__image'>
                <GatsbyImage loading='lazy' image={getImage(page.acf.image?.localFile)} alt={page.name} />
              </div>
              <div className='range-header__content'>
                <hr className='range-header__keyline' />
                <div className='range-header__title'>
                  <h1>{ page.name }</h1>
                </div>
                { page.description &&
                  <div className='range-header__content-content' dangerouslySetInnerHTML={{ __html: page.description }} />
                }
              </div>
            </div>
          </div>
        </section>
        { page.wpChildren?.nodes?.length > 0 &&
          <section className='range-subcats'>
            <div className='range-subcats__inner'>
              <ul>
                { page.wpChildren.nodes.map((el, i) => (
                  <li key={i}>
                    <Link to={`${pathname}${el.slug}`}>
                      <GatsbyImage loading='lazy' image={getImage(el.acf.image?.localFile)} alt={el.name} />
                      <h4>{el.name}</h4>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        }
        <section className='products'>
          <div className='products__inner'>
            <div className='products__sidebar'>
              <h4>Filter Products</h4>
              <div className='products__filters'>
                <div className={`products__filter ${filters[0] && 'active'}`}>
                  <div className='products__filter-heading'>
                  <span onClick={() => this.toggleFilters(0)}>Colour</span>
                    { colour && <button type='button' className='clear' onClick={this.clearColour}>Clear</button> }
                  </div>
                  <ul>
                    { colours?.length > 0 && colours.map((el, i) => (
                      <li className='form__checkbox' key={i}>
                        <input type='radio' name='colour' id={el} value={el} onChange={this.handleChange} />
                        <label htmlFor={el}>{el}</label>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className={`products__filter ${filters[1] && 'active'}`}>
                  <div className='products__filter-heading'>
                    <span onClick={() => this.toggleFilters(1)}>Material</span>
                    { material && <button type='button' className='clear' onClick={this.clearMaterial}>Clear</button> }
                  </div>
                  <ul>
                    { materials?.length > 0 && materials.map((el, i) => (
                      <li className='form__checkbox' key={i}>
                        <input type='radio' name='material' id={el} value={el} onChange={this.handleChange} />
                        <label htmlFor={el}>{el}</label>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className='products__main'>
              <div className='products__sort-wrap'>
                <p>{count} Products</p>
                <div className='products__sort'>
                  <span>Sort by</span>
                  <div className='products__select form__select'>
                    <select name='sort' onChange={this.handleSortChange}>
                      <option value='newest'>Most Recent</option>
                      <option value='oldest'>Oldest</option>
                      <option value='highest-price'>Highest Price</option>
                      <option value='lowest-price'>Lowest Price</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='products__items'>
                { results?.length > 0 && results.map((el, i) => {
                  return (
                    <div className='products__item' key={i}>
                      <ProductCard {...el} addVariantToCart={this.props.addVariantToCart} />
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <ReadyToGetStarted />
      </>
    )
  }
}

export const categoryQuery = graphql`
  query ($id: String!) {
    allWpProduct(
      filter: {productCategories: {nodes: {elemMatch: {id: {eq: $id}}}}}
      sort: {fields: date, order: DESC}
    ) {
      nodes {
        title
        slug
        date
        materials {
          nodes {
            name
            slug
          }
        }
        shopifyProduct {
          images {
            src
            width
            height
          }
          variants {
            admin_graphql_api_id
            title
            price
          }
        }
      }
    }
    wpProductCategory(id: {eq: $id}) {
      name
      slug
      description
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
        schema {
          raw
        }
      }
      acf {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 75
                width: 1000
                formats: [AUTO, WEBP, AVIF]
                placeholder: DOMINANT_COLOR
              )
            }
          }
        }
      }
      wpChildren {
        nodes {
          name
          slug
          acf {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 75
                    width: 1000
                    formats: [AUTO, WEBP, AVIF]
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`

export default CategoryTemplate
