import React, { Component } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getShopifyImage } from 'gatsby-source-shopify'
import Link from '../../utils/link'
import tag from "../../utils/tag"

class ProductCard extends Component {

  _addToCart = () => {
    let { shopifyProduct } = this.props
    let variantId = btoa(shopifyProduct.variants[0].admin_graphql_api_id)
    tag("Add to Cart", shopifyProduct.title)
    setTimeout(() => {
      this.props.addVariantToCart(variantId, 1)
    }, 100)
  }

  render() {

    let { title, slug, shopifyProduct } = this.props

    let imageObject =  {
      originalSrc: shopifyProduct.images[0].src,
      width: shopifyProduct.images[0].width,
      height: shopifyProduct.images[0].height
    }

    const image = shopifyProduct.images.length > 0 && getShopifyImage({
      image: imageObject,
      height: 600,
    })

    return (
      <div className='product'>
        <div className='product__image'>
          { image &&
            <Link to={`/products/${slug}/`}>
              <GatsbyImage loading='lazy' image={image} alt={title} />
            </Link>
          }
        </div>
        <div className='product__content'>
          <Link to={`/products/${slug}/`}>
            <h4 dangerouslySetInnerHTML={{ __html: title }} />
            <span className='product__price'>${ shopifyProduct.variants[0].price }</span>
          </Link>
          { shopifyProduct.variants.length > 1 &&
            <Link to={`/products/${slug}/`} className='product__add-to-cart btn'>
              Select Options
            </Link>
          }
          { shopifyProduct.variants.length === 1 &&
            <button type='button' onClick={this._addToCart} className='product__add-to-cart btn'>
              Add to Cart
            </button>
          }
        </div>
      </div>
    )
  }
}

export default ProductCard
